<template>
  <div class="bg-primary h-screen flex items-center">
    <div class="w-10/12 mx-auto">
      <div class="flex flex-col">
        <PrimaryButton class="mb-5" @click="toScanner('entrance')">
          Scan entrance tickets
        </PrimaryButton>

        <PrimaryButton @click="toScanner('info')">
          Get info ticket
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/components/PrimaryButton.vue';

export default {
  components: {
    PrimaryButton,
  },
  methods: {
    toScanner(type) {
      this.$router.push({
        name: 'Scanner',
        params: {
          typeToScan: type,
        },
      });
    },
  },
};
</script>
