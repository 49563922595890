import gql from 'graphql-tag';

export const myUser = gql`
  query MyUser($jwt: String!) {
    myUser(jwt: $jwt) {
      name
      surename
      email
      phone
    }
  }
`;

export const myQr = gql`
  query MyQr {
    myQr
  }
`;

export const getInfo = gql`
  query Reservation($input: EntranceCheckInput!) {
    reservationByQr(input: $input) {
      id
      weekendID
      teamID
      reservationnumber
      payment_status
      genderOfTeam
      status
      numberofguests
      name
      surename
      entered
      remarks
      internal_remarks
      reservationPayments {
        reservationID
        memberID
        amount
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        status
      }
      team {
        name
        guid
        changedName
        main_booker_user {
          name
          surename
          guid
        }
        members {
          id
          name
          surename
          main_booker
        }
      }
      tent {
        id
        tent_optionID
        quantity
        status
        tent_option {
          name
          capacity_text
        }
      }
    }
  }
`;
