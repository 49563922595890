<template>
  <div />
</template>

<script>
// import { Capacitor, Plugins } from '@capacitor/core';
import { Plugins } from '@capacitor/core';
// const { BarcodeScanner } = Plugins;

export default {
  name: 'BarcodeScanner',
  methods: {
    prepare() {
      console.log('prepair');
      Plugins.BarcodeScanner.prepare();
    },

    async startScan() {
      this.$emit('start');
      this.$emit('update:status', 'start');
      Plugins.BarcodeScanner.hideBackground();
      const result = await Plugins.BarcodeScanner.startScan();
      this.$emit('update:result', result);
      this.$emit('result', result);
      return result;
    },

    stopScan() {
      Plugins.BarcodeScanner.showBackground();
      Plugins.BarcodeScanner.stopScan();
      this.$emit('stop');
      this.$emit('update:status', 'stop');
    },
  },

  deactivated() {
    this.stopScan();
  },

  beforeUnmount() {
    this.stopScan();
  },
};
</script>
