<template>
  <PrimaryButton
    class="fixed top-16 left-4 text-3xl text-white z-10"
    style="width: 40px; height: 40px"
    @click="$router.go(-1)"
    >⇦</PrimaryButton
  >
  <h1 class="absolute w-full text-center mt-16 text-icon text-3xl">
    {{ $route.params.typeToScan }}
  </h1>
  <div class="body">
    <barcode-scanner show="true" ref="barcodescanner" v-model="scanStatus" />

    <transition name="modal-fade">
      <StatusIndicator
        v-if="$route.params.typeToScan == 'entrance' && showPopUp"
        :class="responseStatus == 200 ? 'bg-green-500' : 'bg-red-500'"
      >
        <div
          class="text-3xl text-white fixed left-4 top-3 p-4 z-20"
          @click="togglePopUp"
        >
          x
        </div>

        <div class="flex content-center justify-center items-center h-screen">
          <div v-if="responseStatus == 200 && captain" class="">
            <div
              class="w-screen h-screen flex items-center justify-center h-3 w-3"
              data-v-27871db4=""
            >
              <div
                class="
                  w-3/4
                  animate-ping
                  absolute
                  inline-flex
                  h-3/4
                  w-1/2
                  rounded-full
                  bg-purple-400
                  opacity-75
                "
                data-v-27871db4=""
              ></div>
              <h1 class="block text-3xl text-white" data-v-27871db4="">
                Captain - Good to go !
              </h1>
            </div>
          </div>
          <h1 class="text-3xl text-white" v-else-if="responseStatus == 200">
            Good to go !
          </h1>
          <h1 class="text-xl text-white" v-else>
            {{ responseMsg }}
          </h1>
        </div>
      </StatusIndicator>

      <StatusIndicator
        v-else-if="$route.params.typeToScan == 'info' && showPopUp"
        :class="responseStatus == 200 ? 'bg-white' : 'bg-red-500'"
      >
        <div
          class="text-3xl text-dark text-white fixed left-4 top-3 p-4"
          @click="togglePopUp"
        >
          x
        </div>
        <div
          v-if="responseStatus == 200"
          class="flex flex-col justify-center items-center h-full"
        >
          <div class="mb-3 w-10/12">
            <h3 class="text-xl"><b>Bezoeker:</b></h3>
            <ul class="pl-4 list-disc">
              <li>
                <b>Naam:</b>
                {{ `${reservationInfo?.name} ${reservationInfo?.surename} ` }}
              </li>
              <li>
                <b>Binnen:</b> {{ reservationInfo?.entered ? 'Ja' : 'Nee' }}
              </li>
            </ul>
          </div>

          <div class="mb-3 w-10/12">
            <h3 class="text-xl"><b>Reservation:</b></h3>
            <ul class="pl-4 list-disc">
              <li><b>resNr:</b> {{ reservationInfo?.reservationnumber }}</li>
              <li>
                <b>Volledig betaald:</b> {{ reservationInfo?.payment_status }}
              </li>
              <li><b>Opmerking:</b> {{ reservationInfo?.remarks }}</li>
              <li><b>interne Opmerking:</b> {{ internal_remarks }}</li>
            </ul>
          </div>

          <div class="mb-3 w-10/12">
            <h3 class="text-xl">
              <b>Team:</b> {{ reservationInfo?.team?.name }} -
              {{ reservationInfo?.numberofguests }}
            </h3>
            <ul class="pl-4 list-disc">
              <li><b>Geslacht:</b> {{ reservationInfo?.genderOfTeam }}</li>
              <li>
                <b>Hoofdboeker:</b>
                {{
                  `${reservationInfo?.team?.main_booker_user?.name} ${reservationInfo?.team?.main_booker_user?.surename}`
                }}
              </li>
              <li>
                <b>Naam veranderd:</b>
                {{ reservationInfo?.team?.changedName ? 'Ja' : 'Nee' }}
              </li>
              <li>
                <b>Teamleden:</b>
                <ul class="list-disc pl-4">
                  <li
                    v-for="member in reservationInfo?.team?.members"
                    :key="member.id"
                  >
                    {{ `${member.name} ${member.surename}` }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div class="mb-3 w-10/12" v-if="reservationInfo?.tent.length > 0">
            <h3 class="text-xl"><b>Tenten:</b></h3>
            <ul class="pl-4 list-disc">
              <li v-for="tent in reservationInfo?.tent" :key="tent.id">
                {{
                  `${tent.quantity}x - ${tent.tent_option.name} ${tent.tent_option.capacity_text}`
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="h-full flex items-center justify-center" v-else>
          <h1 class="text-3xl">No user found</h1>
        </div>
      </StatusIndicator>
    </transition>
  </div>
</template>

<script>
// Components
import BarcodeScanner from '@/components/BarcodeScanner';
import StatusIndicator from '@/components/StatusIndicator';
import PrimaryButton from '@/components/PrimaryButton.vue';

import { UserEntrance } from '@/graphql/tickets/mutations.js';
import { getInfo } from '@/graphql/tickets/query.js';

import { Capacitor, Plugins } from '@capacitor/core';

export default {
  name: 'Scanner',
  components: {
    BarcodeScanner,
    StatusIndicator,
    PrimaryButton,
  },

  data() {
    return {
      permissionStatus: null,
      scanStatus: null,
      showPopUp: false,
      responseStatus: null,
      responseMsg: null,
      reservationInfo: null,
      captain: false,
    };
  },
  methods: {
    togglePopUp() {
      this.showPopUp = false;
    },

    async scan() {
      const result = await this.$refs.barcodescanner.startScan();
      if (result?.hasContent) {
        this.handleCode(result.content);
      }
    },

    handleCode(code) {
      if (!code) return false;
      if (this.$route.params.typeToScan === 'entrance') {
        this.scanEntrance(code);
      } else if (this.$route.params.typeToScan === 'info') {
        this.scanInfo(code);
      }
    },

    back() {
      this.go();
    },

    scanEntrance(code = null) {
      const self = this;
      this.$apollo
        .mutate({
          mutation: UserEntrance,
          variables: {
            input: {
              jwt: code,
            },
          },
        })
        .then((data) => {
          if (data?.data?.EntranceCheck?.status === 401) {
            this.$router.push({
              name: 'Login',
            });
          } else {
            self.responseStatus = data.data.EntranceCheck.status;
            self.responseMsg = data.data.EntranceCheck.statusMsg;
            self.showPopUp = true;
            self.captain = data.data.EntranceCheck.captain;
            if (data.data.EntranceCheck.status == 200 && !self.captain) {
              setTimeout(() => self.togglePopUp(), 1500);
            }
            self.scan();
          }
        })
        .catch((error) => {
          self.responseMsg = error;
          self.responseStatus = 400;
          self.showPopUp = true;
        });
    },

    scanInfo(code = null) {
      const self = this;
      this.$apollo
        .mutate({
          mutation: getInfo,
          variables: {
            input: {
              jwt: code,
            },
          },
        })
        .then((data) => {
          if (data?.data?.reservationByQr?.status === 401) {
            this.$router.push({
              name: 'Login',
            });
          } else {
            self.showPopUp = true;
            self.responseStatus = data?.data?.reservationByQr ? 200 : 400;
            self.reservationInfo = data?.data?.reservationByQr;
            self.scan();
          }
        })
        .catch((error) => {
          self.responseMsg = error;
          self.responseStatus = 400;
          self.showPopUp = true;
        });
    },

    async checkPermission() {
      const status = await Plugins.BarcodeScanner.checkPermission();
      if (!status.granted) {
        // the user denied permission for good
        // redirect user to app settings if they want to grant it anyway
        const c = confirm(
          'If you want to grant permission for using your camera, enable it in the app settings.'
        );
        if (c) {
          Plugins.BarcodeScanner.openAppSettings();
        }
      } else {
        this.scan();
      }
    },
  },

  activated() {
    this.scan();
  },

  mounted() {
    const isBarcodeScannerAvailable =
      Capacitor.isPluginAvailable('BarcodeScanner');

    if (isBarcodeScannerAvailable) {
      console.log('isBarcodeScannerAvailable');
      // this.initPushNotifications();
    } else {
      console.log('no barcode scanner');
    }

    console.log('Capacitor.isNative', Capacitor.isNative);

    this.checkPermission();
  },

  deactivated() {
    this.scanStatus = null;
  },
};
</script>

<style lang="scss" scoped>
$border-width: 3px;
$corner-size: 20px;
$border-color: var(--base-color-orange);

.scan-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100vh;
  padding-bottom: 1em;
  // background-color: rgba(0, 0, 0, 0);

  .square {
    width: 70%;
    display: flex;
  }

  .scan-box {
    // box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4);
    margin: 0.65em;

    .corners {
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: $corner-size;
        height: $corner-size;
        border: $border-width solid $border-color;
      }

      &.top {
        &:before {
          left: 0;
          top: 0;
          border-bottom: none;
          border-right: none;
        }

        &:after {
          right: 0;
          top: 0;
          border-left: none;
          border-bottom: none;
        }
      }

      &.bottom {
        &:before {
          bottom: 0;
          right: 0;
          border-left: none;
          border-top: none;
        }

        &:after {
          bottom: 0;
          left: 0;
          border-right: none;
          border-top: none;
        }
      }
    }
  }

  .text {
    color: #fff;
    // text-shadow: 0 0 7px rgb(0 0 0 / 45%);
    z-index: 1;
    margin-bottom: 1.5em;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    bottom: auto;
    transform: translateY(-50%);
    font-weight: 600;

    h2 {
      font-size: 1.75em;
      color: #fff;
      margin-bottom: 0.25em;
      font-weight: 800;
    }

    p {
      line-height: normal;
      max-width: 85%;
      margin: 0 auto;
    }
  }

  .btn.back {
    max-width: 110px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: calc(1em + constant(safe-area-inset-bottom, 0));
    bottom: calc(1em + env(safe-area-inset-bottom, 0));
    background: var(--base-color-orange);
  }
}

.loading-section {
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 2em;
  }

  .icon {
    margin-left: 1em;
    width: 2em;
    height: 2em;
  }
}

.unknown-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
