<template>
  <div class="fixed z-20 w-screen h-screen">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
    },
  },
};
</script>
