<template>
  <div class="bg-primary h-screen flex items-center">
    <div class="w-10/12 mx-auto">
      <div class="flex flex-col">
        <form
          class="w-10/12 mx-auto text-center flex flex-col"
          ref="form"
          @submit.prevent="submitLogin($event)"
        >
          <input placeholder="Password" type="password" name="input-pass" />
          <button type="submit">
            <PrimaryButton class="inline-block px-9">
              <div class="py-2 text-aditionalTxt text-lg">Login</div>
            </PrimaryButton>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { loginScanner } from '@/graphql/tickets/mutations';
export default {
  methods: {
    async submitLogin(e) {
      e.preventDefault();
      const password = e.target.elements['input-pass'].value;

      try {
        const res = await this.$apollo.mutate({
          mutation: loginScanner,
          variables: {
            input: {
              password,
            },
          },
        });

        if (res?.data?.loginScanner?.status !== 200) {
          alert('er ging iets mis');
          return;
        } else {
          this.$router.push({
            name: 'Options',
          });
        }
      } catch (e) {
        console.error(e);
        this.errorMsg = e;
        this.showModal = true;
      }
    },
  },
};
</script>
