import { createApp } from 'vue';
import App from './App.vue';
import { apolloProvider } from '@/apollo/provider.js';
// import VueCookies from 'vue-cookies';

import 'tailwindcss/tailwind.css';

import router from './router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUser);

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(apolloProvider)
  .use(router);

router.app = app;

app.use(router).mount('#app');
