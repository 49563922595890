import gql from 'graphql-tag';

export const UserEntrance = gql`
  mutation EntranceCheck($input: EntranceCheckInput!) {
    EntranceCheck(input: $input) {
      status
      statusMsg
      captain
    }
  }
`;

export const loginScanner = gql`
  mutation ($input: LoginScannerInput!) {
    loginScanner(input: $input) {
      status
      statusMsg
    }
  }
`;
