import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/auth/Login.vue';
import Scanner from '@/views/Scanner.vue';
import Options from '@/views/Options.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/options',
    name: 'Options',
    component: Options,
  },
  {
    path: '/scanner',
    name: 'Scanner',
    component: Scanner,
    props: (route) => ({
      ...route.params,
    }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
